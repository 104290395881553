import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./pages/auth/authContext";
import { UsernameProvider } from "./pages/auth/username";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <UsernameProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
      </UsernameProvider>
    </BrowserRouter>
  // {/* </React.StrictMode> */}
);

reportWebVitals();
