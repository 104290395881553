import React, { useState } from "react";
import logo from "../../assets/activeInvestors.svg";
import axios from "axios";
import tickedCircle from "../../assets/tickedCircle.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";

function RazorpayPaymentButton() {
  const Navigate = useNavigate();
  const [ifCoupon, setCoupon] = useState("");
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setInput(event.target.value);
  };
  const activePaymentType = JSON.parse(
    sessionStorage.getItem("company_legal_name")
  ).type;
  const KEY = process.env.REACT_APP_RAZORPAY_PUBLISHABLE_KEY;
  // const KEY = process.env.REACT_APP_RAZORPAY_PUBLISHABLE_TEST_KEY;

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    try {
      setLoading(true);
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      console.log(window);

      if (activePaymentType === "Company") {
        const data = {
          email: sessionStorage.getItem("email"),
          coupon: ifCoupon,
        };
        const result = await axios.post("https://factacy.io/checkout", data);
        if (!result) {
          alert("Server error. Are you online?");
          return;
        }

        const { amount, id: order_id, currency } = result.data;
        const options = {
          key: KEY, // Enter the Key ID generated from the Dashboard
          amount: amount.toString(),
          currency: currency,
          name: "Factacy Private Limited",
          description: "Transaction of Investor list.",
          image: { logo },
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              companyId: sessionStorage.getItem("uuid"),
              companyName: JSON.parse(
                sessionStorage.getItem("company_legal_name")
              ).name,
              email: sessionStorage.getItem("email"),
              type: activePaymentType,
            };

            const result = await axios.post(
              "https://factacy.io/paymentverification",
              data
            );

            if (result.status === 200) {
              alert("Your payment is successfull!");
              Navigate("/action/download");
            } else if (result.status === 400) {
              alert("Payment unsuccessful, please try again.");
            }
          },
          theme: {
            color: "#2475bb",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        const data = {
          email: sessionStorage.getItem("email"),
        };
        const result = await axios.post(
          "https://factacy.io/checkout_sector",
          data
        );

        if (!result) {
          alert("Server error. Are you online?");
          return;
        }

        const { amount, id: order_id, currency } = result.data;
        const options = {
          key: KEY, // Enter the Key ID generated from the Dashboard
          amount: amount.toString(),
          currency: currency,
          name: "Factacy Private Limited",
          description: "Transaction of Investor list.",
          image: { logo },
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              sector_id: sessionStorage.getItem("uuid"),
              sector: JSON.parse(sessionStorage.getItem("company_legal_name"))
                .name,
              email: sessionStorage.getItem("email"),
              type: activePaymentType,
            };

            const result = await axios.post(
              "https://factacy.io/paymentverification_sector",
              data
            );

            if (result.status === 200) {
              alert("Your payment is successfull!");
              Navigate("/action/download");
            } else if (result.status === 400) {
              alert("Payment unsuccessful, please try again.");
            }
          },
          theme: {
            color: "#2475bb",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  const data = {
    sectionName: "Customised Investor List Pricing",
    list: [
      {
        priceStr: "5,999",
        priceInt: 5999,
        GST: "1619",
        type: "Single Download",
        details: "Your Startup's list of Potential Investors",
        features: [
          "Curated list of Investors relevant to your company",
          "Advanced Recommendation Logics sorting most relevant investors",
          "Investors Specific to your startup's sector and investment roundound",
          "Download in excel",
        ],
      },
    ],
  };

  const sectorData = {
    sectionName: "Customised Investor List Pricing",
    list: [
      {
        priceStr: "1,999",
        priceInt: 1999,
        GST: "419",
        type: "Sector specific active investors",
        details: "List of Investors Active in a Sector",
        features: [
          "Updated list of upto 500 sector specific investors",
          "Sorted by most Active and recent Investors in a sector",
          "Sector Details of the Investor",
          "Contact Details like Website, Linkedin, Twitter, EMail",
          "Download in excel",
        ],
      },
    ],
  };

  return (
    <div>
      <section className="py-10 flex flex-col items-center gap-9">
        <div className="flex flex-col lg:flex-row gap-8">
          {(activePaymentType === "Company" ? data : sectorData).list.map(
            (item, index) => (
              <div
                key={index}
                className=" rounded-lg bg-[#fff] flex flex-col gap-2 max-w-[20rem] p-6"
              >
                <p className="flex flex-col pb-4">
                  <span className={`text-lg font-semibold`}>{item.type}</span>
                  <span className={`text-sm text-[#7b7b7b]`}>
                    {item.details}
                  </span>
                </p>

                <div className="flex gap-4 justify-between items-center ">
                  <p className="flex gap-1 text-4xl font-semibold">
                    &#x20B9;
                    {ifCoupon == "STARTUPINDIA" ||
                    ifCoupon == "SHARKTANKFAM" ||
                    ifCoupon == "TISFACTACY" ||
                    ifCoupon == "TIESTARTUPS" ? (
                      <span className="line-through">{item.priceStr}</span>
                    ) : (
                      <span>
                        {item.priceStr}
                        {/* <span className="text-xs align-sub">+18% GST</span> */}
                      </span>
                    )}
                    {ifCoupon == "STARTUPINDIA" ? (
                      <span className="text-primaryBlue"> &#x20B9;3,999*</span>
                    ) : ifCoupon == "SHARKTANKFAM" ? (
                      <span className="text-primaryBlue"> &#x20B9;3,499*</span>
                    ) : ifCoupon == "TISFACTACY" ? (
                      <span className="text-primaryBlue"> &#x20B9;3,539*</span>
                    ) : ifCoupon == "TIESTARTUPS" ? (
                      <span className="text-primaryBlue"> &#x20B9;3,539*</span>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                {activePaymentType === "Company" && (
                  <div className="flex gap-2 items-center border-[1px] rounded-md p-2 border-primaryBlue">
                    <input
                      className="w-full outline-none uppercase text-sm"
                      value={input}
                      onChange={handleChange}
                      placeholder="Enter promocode"
                    />
                    <p
                      className="text-primaryBlue text-xs font-semibold cursor-pointer"
                      onClick={() => {
                        setCoupon(input.toUpperCase());
                        setInput("");
                      }}
                    >
                      APPLY
                    </p>
                  </div>
                )}
                {ifCoupon && ifCoupon == "STARTUPINDIA" ? (
                  <p className="text-[#2b9445] text-sm">
                    You saved &#x20B9;2,000!
                  </p>
                ) : ifCoupon == "SHARKTANKFAM" ? (
                  <p className="text-[#2b9445] text-sm">
                    You saved &#x20B9;2,500!
                  </p>
                ) : ifCoupon == "TISFACTACY" ? (
                  <p className="text-[#2b9445] text-sm">
                    You saved &#x20B9;2,460!
                  </p>
                ) : ifCoupon == "TIESTARTUPS" ? (
                  <p className="text-[#2b9445] text-sm">
                    You saved &#x20B9;2,460!
                  </p>
                ) : (
                  ifCoupon && (
                    <p className="text-[#ff4a4a] text-sm">
                      Coupon does not exist!
                    </p>
                  )
                )}
                {loading ? (
                  <div className="w-full flex flex-col items-center">
                    <Loader />
                  </div>
                ) : (
                  <button
                    className="text-center mt-4 py-3 px-4 lg:px-8 rounded-full text-base leading-6 font-semibold text-[#ffffff] cursor-pointer bg-primaryBlue w-full"
                    onClick={displayRazorpay}
                  >
                    {/* <p className=""> */}
                      Pay
                    {/* </p> */}
                  </button>
                )}

                <div className="flex flex-col gap-2 flex-grow">
                  <p className=" text-sm">This Includes :</p>
                  <div className=" flex-grow flex flex-col gap-2">
                    {item.features.map((feature, ind) => (
                      <div key={ind} className=" flex gap-2 items-start">
                        <img
                          src={tickedCircle}
                          className=" pt-1"
                          alt="tickedCircle"
                        />
                        <p className=" text-[0.8rem]">{feature}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {/* <p className="text-start text-xs w-full px-6">
          *18% GST extra, as applicable.
        </p> */}
      </section>
    </div>
  );
}
export default RazorpayPaymentButton;
