import React, { useRef, useEffect, useState } from 'react';


// const Sumary = ({ data }) => {


//     const P = ({ item }) => <span>{item}</span>;

//     if (data.Reference_Id && data.Reference_Id !== "No" && data.Company_Name && data.Company_Name !== "No") {
//         const Clickable = () => <a className=' text-primaryBlue font-semibold cursor-pointer' href={`https://www.factacyinsights.com/company-intelligence/company/${data.Company_Name}/${data.Reference_Id}`}>{ " "+ data?.Brand_Name + " "}</a>;
//         const Deal_Summary = data?.Deal_Summary?.split(data?.Brand_Name);
//         return <div>
//             {Deal_Summary.map((item, index) => index !== Deal_Summary.lenght - 1 ? (<React.Fragment key={index}><P item={item} /><Clickable /></React.Fragment>) : (<P key={index} item={item} />))}
//         </div>
//     }
//     else {
//         return <><P item={data?.Deal_Summary} /></>
//     }

// }
const Blog = ({ blog }) => {

  const capitalizeFirstLetter = (string)=> string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);

  const [existingMetaTags, setExistingMetaTags] = useState({
    description: document?.querySelector('meta[name="description"]')?.getAttribute("content"),
    keywords: document?.querySelector('meta[name="keywords"]')?.getAttribute("content"),
    title: document.getElementsByTagName("title")[0].innerText,
  });

  const Investor_Thesis = blog?.Investor_Info?.Investor_Thesis;
  const Title = blog?.Investor_Info?.Title;
  const keywords = blog?.keywords;
  const Investor_Name = blog?.Investor_Info?.Investor;
  const Investor_Id = blog?.Investor_Id;
  const Summary = blog?.Investor_Info?.Summary;

  useEffect(() => {

    if (blog) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", Investor_Thesis);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", keywords);

      document.getElementsByTagName("title")[0].innerText = Title ?? existingMetaTags.title;
    }


    return () => {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", existingMetaTags.description);

      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", existingMetaTags.keywords);

      document.getElementsByTagName("title")[0].innerText =
        existingMetaTags.title;
    };
  }, []);


  return (
    // <div className='h-[calc(100%-2rem)] flex flex-col justify-center'>
    <div className=''>
      <h1 className='my-6 text-3xl font-medium py-2'>{Title}</h1>
      {Summary?.split('\n').filter(item => item).map((item, index) => (<p className='mb-4' key={index}>{item}</p>))}
      <h4 className='mt-4 font-semibold text-lg'>What is {Investor_Name}'s Investment thesis? What kind of Startups should approach {Investor_Name}?</h4>
      <p className='mt-1'>{Investor_Thesis}</p>

      <p className='mt-2 font-medium text-center italic'
        style={{ fontWeight: 400 }}
      >
        Factacy Insights is a platform specializing in financial news intelligence. For more information about
        <a href={`https://www.factacyinsights.com/investor-intelligence/investor/${Investor_Name?.toLowerCase()?.split(" ")?.join("-")}/${Investor_Id}`} target='_blank' className=' text-primaryBlue font-semibold'>{" " + capitalizeFirstLetter(Investor_Name)}</a>
        , please log onto
        <a href='https://www.factacyinsights.com/' target='_blank' className=' text-primaryBlue font-semibold'>   Factacy Insights </a>
      </p>
    </div>
  )
}
export default Blog;






// return loading ?
//  (<div className=' w-full flex justify-center'> <Loader /></div>)
//  :
//  (
//     <div  className=" m-2 mt-14 lg:mt-2 p-4 lg:p-8 flex flex-col gap-2 min-h-screen bg-white rounded-lg">
//         <h1 className='text-3xl font-bold '>{heading}</h1>
//         <span className=" px-2 py-1 rounded-[3px] bg-gray-100 w-fit text-[0.8rem]">{Alphanumeric_Date(reports?.[0]?.Epoch * 1000)?.split("-").join(" ")}</span>
//         <p className=' text-xl font-semibold'>{reports?.[0]?.Introduction}</p>
//         <div className=' flex flex-col gap-5'>
//             <div className=' flex flex-col gap-5'>
//                 {reports?.[0]?.Summary_Data?.map((item, index) => <Sumary data={item} key={index} />)}
//             </div>
//         </div>
//         <p className=' text-lg font-semibold'>{reports?.[0]?.Conclusion}</p>
//     </div>)