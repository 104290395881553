import React from "react";

const Enquire = () => {
  return (
    <div className="fadeIncrease w-screen h-screen flex flex-col items-center pt-20">
      <iframe
      className=" w-full h-full"
        // width="640px"
        // height="680px"
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=gP1OP7dTgUeEalamI0sWT9AOVOIYwgpDrvezsX4WMCFUM0RBQ1IzNVdNMkZDQldNS1BPT1ROU0NaNi4u&embed=true"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        // style="border: none; max-width:100%; max-height:100vh"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscreen
      >
      </iframe>
    </div>
  );
};

export default Enquire;
