import React from "react";
import RazorpayPaymentButton from "../../components/stripe/StripePricingTable";
import Promo from "../../assets/Icons/Promo";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();
  const uuid = sessionStorage.getItem("uuid");
  const activePaymentType = JSON.parse(
    sessionStorage.getItem("company_legal_name")
  )?.type;

  return (
    <div className="fadeIncrease  overflow-y-auto rounded-xl shadow-xl px-8 min-h-[500px] flex-col bg-[#fff] border-[1px] border-[#f1f1f1] flex items-center justify-center">
      {activePaymentType === "Company" && (
        <div className="w-content py-2 lg:px-8 px-2 mt-8 border-[1px] border-dashed border-primaryBlue rounded-md flex gap-2 items-center">
          <Promo />
          <p className="text-start text-xs lg:text-lg">
            Use code{" "}
            <span className="font-semibold text-primaryBlue text-sm lg:text-lg">
              STARTUPINDIA
            </span>{" "}
            to get &#x20B9;2,000 off.
          </p>
        </div>
      )}
      {uuid ? (
        <RazorpayPaymentButton />
      ) : (
        <div className="h-40 flex flex-col justify-center items-center">
          <p className="text-sm font-semibold">Please search for a company.</p>
          <button
            className="m-3 px-3 py-1 font-semibold text-buttonCTA border-buttonCTA border-2 rounded-full hover:text-[#fff] hover:bg-buttonCTA"
            onClick={() => navigate("/action/find")}
          >
            Find Investors
          </button>
        </div>
      )}
    </div>
  );
};
export default Payment;
