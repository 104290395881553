import React from "react";
import tickedCircle from "../../assets/tickedCircle.svg";
import PrimaryButton from "../../components/PrimaryButton";
import Promo from "../../assets/Icons/Promo";

const Pricing = ({ handleButtonClick }) => {
  const pricingTypeBg = ["bg-blue", "bg-purple"];
  const data = {
    sectionName: "Fundraising starts here",
    list: [
      {
        priceStr: "1,999",
        priceInt: 1999,
        type: "Sector specific",
        details: "List of Investors Active in a Sector",
        features: [
          "Updated list of upto 500 sector specific investors",
          "Sorted by most Active and recent Investors in a sector",
          "Sector Details of the Investor",
          "Contact Details like Website, Linkedin, Twitter, Email",
          "Download in excel",
        ],
        text: "Start Now",
        item: "sector",
      },
      {
        priceStr: "5,999",
        priceInt: 5999,
        type: "Startup specific",
        details: "Your Startup's list of Potential Investors",
        features: [
          "Curated list of Investors relevant to your company",
          "Advanced Recommendation Logics sorting most relevant investors",
          "Investors Specific to your startup's sector and investment round",
          "Download in excel and pdf formats",
        ],
        text: "Start Now",
        item: "startup",
      },
      {
        // priceStr: "8,999",
        // priceInt: 8999,
        type: "Multiple Downloads",
        details: "Download file 3 times, anytime in the next 6 months",
        features: [
          "All the features in Single Download, plus",
          "2 additional downloads in the next 6 months",
          "Updated lists as per the latest investments made in your sector",
          "The investor list is specific to a single company as selected during purchase.",
          "Factacy Insights recommends downloads after every 2 months",
        ],
        text: "Enquire Now",
        item: "enquire",
      },
    ],
  };
  return (
    <>
      <section className="min-h-screen  py-10 flex flex-col flex-auto items-center gap-9">
        <h1 className=" text-4xl font-bold text-center">{data.sectionName}</h1>
        <div className="flex flex-col lg:flex-row flex-wrap gap-8">
          {data.list.filter(item => window.location.pathname !== "/" && item.item === "enquire" ? false : true).map((item, index) => (
            <div
              key={index}
              className=" rounded-lg bg-[#fff] flex flex-col items-center shadow-lg max-w-[22rem] border-[1px] border-[#e3e3e3]"
            >
              <div className="flex  gap-4  border-b-[0.0125rem] border-[#868686] lg:p-6 p-4 lg:min-h-32">
                <div className="flex flex-col gap-2">
                  <span className={`text-xl font-semibold`}>{item.type}</span>
                  <p className="text-xs text-[#666666]">{item.details}</p>
                </div>
                <span className=" text-4xl font-semibold">
                  {item.item !== "enquire" ? "₹" : ""}
                  
                  {item.priceStr}
                </span>
              </div>
              {item.item === "startup" && (
                <div className="w-fit py-2 mt-3 lg:px-4 mx-2 px-2 border-[1px] border-dashed border-primaryBlue rounded-md flex gap-2 items-center">
                  <Promo />
                  <p className="text-start text-xs lg:text-xs">
                    Use code{" "}
                    <span className="font-semibold text-primaryBlue text-sm lg:text-base">
                      STARTUPINDIA
                    </span>{" "}
                    to get &#x20B9;2000 off.
                  </p>
                </div>
              )}
              <div className=" p-6 flex flex-col gap-4 flex-grow">
                <p className=" text-base">This Includes</p>
                <div className=" flex-grow flex flex-col gap-2">
                  {item.features.map((feature, ind) => (
                    <div key={ind} className=" flex gap-2 items-start">
                      <img
                        src={tickedCircle}
                        className=" pt-1"
                        alt="tickedCircle"
                      />
                      <p className=" text-[0.9rem]">{feature}</p>
                    </div>
                  ))}
                </div>
                <div className=" justify-self-end">
                  <PrimaryButton
                    text={item.text}
                    item={item.item}
                    navigateRoute={"/action/find"}
                    handleButtonClick={handleButtonClick}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
export default Pricing;
