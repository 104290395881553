import React, { useEffect, useState } from 'react';
import Auth from '../pages/register';
import CloseIcon from '../assets/Icons/CloseIcon';

const PopUp = ({ setPopUp }) => {
    const [scrolledPx, setScrollPx] = useState(window.scrollY);

    useEffect(() => {
        const body = document.querySelector('body');
        const app = document.querySelector('html');

        app.style.scrollBehavior = 'auto'
        body.style.position = "fixed";
        body.style.top = `-${scrolledPx}px`;
        return () => {
            body.style.position = "";
            window.scrollTo(0, scrolledPx);
            app.style.scrollBehavior = 'smooth'
        }
    }, []);
    return (
        <div className=' fixed z-[100] w-screen flex justify-center bg-[#1b1b1b91]' style={{ height: "calc(100vh + 8rem)", top: '0px' }}>
        {/* <div className=' fixed z-[100] w-screen flex justify-center bg-[#ffffff83] backdrop-blur' style={{ height: "calc(100vh + 8rem)", top: '0px' }}> */}
            <div className='mt-6 lg:mt-36 relative w-fit'>
                <span className=' absolute right-4 top-4 cursor-pointer ' onClick={() => setPopUp(false)}><CloseIcon color={"#0000FF"} /></span>
                <div className=' w-full'>
                    <Auth fromPopUp={true} />
                </div>
            </div>
        </div>
    )
}

export default PopUp;