import React from 'react'

const ShippingAndDelivery = () => {
  return (
    <div className='py-4 px-4 mb-4 lg:py-28 lg:px-32 lg:mb-20 mt-12 lg:mt-0 h-max font-roboto'>
    <div className='flex flex-col gap-3 bg-white p-8 bg-opacity-50 rounded-3xl' style={{boxShadow: "0px 0px 50.465946197509766px 0px rgba(0, 0, 0, 0.08"}}>
        <h2  className='text-4xl font-bold'>Shipping & Delivery Policy</h2>
        <p>At Factacy, we are committed to providing a seamless and reliable experience when it comes to shipping and delivering investor data reports to our users. Please review our policy below for more information:</p>

        <h3>Delivery Methods:</h3>
        <ul  className='list-decimal px-4'>
            <li><strong>Digital Delivery:</strong> Our investor data reports are available for download directly from our platform upon successful completion of your purchase. You will have instant access to the purchased reports within your account dashboard.</li>
            <li><strong>Instant Access:</strong> For subscription services, you will have instant access to the available reports within your account dashboard upon subscription activation.</li>
        </ul>

        <h3>Delivery Timeframes:</h3>
        <ul  className='list-decimal px-4'>
            <li><strong>Instant Access:</strong> For subscription services, you will have immediate access to the available reports within your account dashboard upon subscription activation.</li>
            <li><strong>Digital Delivery:</strong> For one-time purchases of individual reports or data sets, you will receive the download link on the platform, instantly after completing your purchase.</li>
        </ul>

        <h3>Shipping Fees:</h3>
        <p><strong>Digital Products:</strong> Since our products are delivered digitally, there are no shipping fees associated with your purchase.</p>

        <h3>Delivery Issues:</h3>
        <p><strong>Accessing Purchased Reports:</strong> If you encounter any issues accessing the purchased reports on our platform, please contact our support team for assistance. We are available to help resolve any technical difficulties promptly.</p>

        <h3>Delivery Confirmation:</h3>
        <p><strong>Account Notification:</strong> Upon completing your purchase, you will receive a confirmation notification within your account dashboard, indicating that the purchased reports are available for download.</p>

        <p>Factacy reserves the right to update or modify this Shipping & Delivery Policy at any time without prior notice. By using our services, you agree to abide by the terms outlined in this policy.</p>

        <p>If you have any questions or concerns regarding our Shipping & Delivery Policy, please don't hesitate to contact us at <a className='underline' href="mailto:connect@factacy.ai">connect@factacy.ai</a>.</p>
    </div>
    </div>
  )
}

export default ShippingAndDelivery