import React from "react";
import { useNavigate } from "react-router-dom";

const PrimaryButton = ({ text, item, handleButtonClick }) => {
  const Navigate = useNavigate();
  const redirectUser = (item) => {
    if (window.location.pathname === "/" && item !== "enquire") {
      Navigate("/action/find#pricing");
    } else if (window.location.pathname === "/" && item === "enquire") {
      Navigate("/enquire");
    } else {
      handleButtonClick(item);
    }
  };
  return (
    <button
      className=" py-3 px-4 lg:px-8 rounded-full text-base leading-6 font-semibold text-[#ffffff] cursor-pointer bg-buttonCTA w-fit"
      onClick={() => redirectUser(item)}
      // href={`${text === 'Enquire Now' ? "/enquire" : "/action/find" }`}
      // onClick={() => window.location.pathname === "/" && item !== "enquire" ? Navigate(`/action/find#pricing`)  : handleButtonClick(item)}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
