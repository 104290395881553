// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ isAuthenticated: false });
  const [error, setErr] = useState("");

  // Check for the access token in localStorage on app load
//   useEffect(() => {
//     const storedAccessToken = localStorage.getItem("accessToken");
//     if (storedAccessToken) {
//       setUser({ isAuthenticated: true });
//     }
//   }, []);

  // Function to log the user in
//   const login = async (userData) => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "*",
//       },
//     };
//     try {
//       const userLogin = {
//         email: userData.username,
//         password: userData.password,
//       };
//       const response = await axios.post(
//         `https://factacyinsights.in/signin`,
//         userLogin
//       );
//       if (response.status === 200) {
//         const data = JSON.stringify(response.data);
//         localStorage.setItem("accessToken", data.accessToken);

//         localStorage.setItem("username", userData.username);

//         setUser({ isAuthenticated: true });
//         // user.isAuthenticated== true
//         window.location.href = "/";
//       } else {
//         // Handle login error
//         setErr("Invalid email or password");
//       }
//     } catch (error) {
//       if (error.response.status === 400) {
//         setErr('Invalid email or password')
//       }
//     }
//   };

  // Function to register the user
  const register = async (userData) => {
    try {
      const userRegister = {
        email: userData.email,
        password: userData.password,
      };
      const response = await axios.post(
        `https://factacyinsights.in/signup`,
        userRegister
      );
      console.log(response.status);
      if (response.status === 201) {
        const data = JSON.stringify(response.data);
        // Store the access token in localStorage
        localStorage.setItem("accessToken", data.accessToken);
        setUser({ isAuthenticated: true });
        window.location.href = "/";
      } else if (response.status === 500) {
        setErr("We ran into an internal server error");
        console.log("server error");
      } else if (response.status === 200) {
        setErr("An account from this email already exists.");
        console.log("server error"); }
      
      else {
        setErr("An account from this email already exists.");
      }
    } catch (error) {
      if (error.message == "Request failed with status code 400") {
        setErr("An account from this email already exists.");
      } else {
        console.error(error);
      }
    }
  };

  // Function to log the user out
//   const logout = () => {
//     localStorage.removeItem("accessToken");
//     setUser({ isAuthenticated: false });
//   };

  return (
    // <AuthContext.Provider value={{ user, login, logout, register, error }}>
    <AuthContext.Provider value={{ user, register, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
