import React from "react";
import TwitterIcon from "../../assets/Icons/Twitter";
import FacebookIcon from "../../assets/Icons/Facebook";
import Linkedin from "../../assets/Icons/Linkedin";
import FactacyFullIcon from "../../assets/Icons/FactacyFullIcon";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();
  const Navigate = useNavigate();
  return (
    <footer className=" pb-4 lg:px-16 px-2 py-6\2 flex flex-col lg:gap-6 gap-8 h-fit w-full bg-white xl:container">
      <hr style={{ border: "1px solid #D4D4D8" }} />
      <div className=" flex flex-col lg:flex-row justify-between gap-8">
        <div className="flex flex-col min-w-96 gap-4 lg:gap-0">
          <FactacyFullIcon />
          <p
            className="font-normal font-roboto lg:text-base text-sm bottom-8"
            style={{ color: "#52525B" }}
          >
            Building AI solutions for your business problems.
          </p>
          <div className=" flex-grow flex flex-row lg:items-end gap-6">
            <button
              className="bg-buttonCTA py-4 lg:px-8 px-5 rounded-full text-base leading-6 font-normal text-[#fff] w-fit h-14"
              onClick={()=>Navigate("/action/find")}
            >
              Start Now
            </button>
            <a
              href="mailto:connect@factacy.ai"
              className=" py-4 lg:px-8 px-5 rounded-full text-base leading-5 font-normal border-2 border-black h-14 w-fit hover:bg-black hover:text-white"
            >
              Enquire Now
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:gap-8 items-start lg:items-end text-sm">
          <div className="flex flex-row gap-2 cursor-pointer">
            <a href="https://www.linkedin.com/company/factacy/" target="_blank">
              <Linkedin />
            </a>

            <a href="https://twitter.com/FactacyAI/" target="_blank">
              <TwitterIcon />
            </a>

            <a href="https://www.facebook.com/factacydotAI/" target="_blank">
              <FacebookIcon />
            </a>
          </div>
          <p className=" text-zinc-500 font-medium lg:text-end">
            <span className=" font-semibold">Location: </span>
            <br /> 21B, Udyog Vihar, Sector 18, Gurugram <br /> Haryana, India
            122008
          </p>
          <div
            className="flex flex-col leading-5 lg:text-sm text-xs"
            style={{ color: "#52525B" }}
          >
            <p className=" text-end font-semibold text-[#000]">
              @ {date} factacyinsights
            </p>
            <div className="flex flex-col lg:flex-row gap-1">
              <a href="/terms-and-conditions" className=" cursor-pointer">
                Terms and conditions
              </a>
              <span className=" hidden lg:block"> | </span>
              <a href="/privacy-policy" className=" cursor-pointer">
                Privacy Policy
              </a>
              <span className=" hidden lg:block"> | </span>
              <a href="/contact-us" className=" cursor-pointer">
                Contact us
              </a>
              <span className=" hidden lg:block"> | </span>
              <a href="/cancellation-and-refund" className=" cursor-pointer">
                Refund
              </a>
              <span className=" hidden lg:block"> | </span>
              <a href="/shipping-and-delivery" className=" cursor-pointer">
                Shipping
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;