// import * as yup from 'yup';
// const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/


// export const basicSchema = yup.object().shape({
//     email: yup.string().email("Please enter a valid email").required("Email can not be blank"),
//     password: yup.string().min(5).matches(passwordRules, { message: "Please create a stronger password" }).required("Password can not be blank"),
//     confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords do not match").required("Required ")
// })

import * as yup from 'yup';

export const signUpSchema = yup.object({
    firstName: yup.string().max(100).required("first name is required"),
    lastName: yup.string().max(100).required("last name is required"),
    email: yup.string().email().required("Email is required"),
    phoneNumber: yup.number().required("Phone Number is required"),
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
    confirmPassword: yup.string().required("").oneOf([yup.ref('password'), null], "password should match"),
    companyName: "",
    userName: "",
    id: "",
    gstin: ""
});
export const loginSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
});

export const forgetPasswordSchema = yup.object({
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
    confirmPassword: yup.string().required("").oneOf([yup.ref('password'), null], "password should match"),
});
export const emailSchema = yup.object({
    email: yup.string().email().required("Email is required"),
});