import React, { useEffect, useState } from "react";
import axios from "axios";
import factacy from "../../assets/factacy_logo_1.svg";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";

const SectorSearch = ({ type }) => {
  const [sector, setSector] = useState([]);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSectors = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://factacyinsights.in/main_sector"
        );
        setSector(response.data);
        setLoading(false);
      } catch (err) {}
    };
    fetchSectors();
  }, []);

  const submitSectorHandler = (NAME) => {
    const ID = uuidv4();
    const storeable = {
      name: NAME,
      type: type,
    };
    sessionStorage.setItem("company_legal_name", JSON.stringify(storeable));
    sessionStorage.setItem("uuid", ID);

    navigate("/action/confirm");
  };
  return (
    <div className="flex flex-col gap-4">
      {loading && <Loader />}
      {sector.length > 0 && (
        <div className="flex justify-between gap-2 items-center bg-white w-full px-4 py-1 bg-[#fff] rounded-md border-[1px] border-[#C0C0C0]">
          <svg
            className=" cursor-pointer"
            width="23"
            height="21"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
              fill="#71717A"
            />
          </svg>
          <input
            placeholder="search for sector..."
            className="p-2 rounded-md w-full appearance-none focus:outline-none "
            type="text"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      )}

      <div className="flex flex-wrap gap-5 pb-5">
        {sector &&
          sector
            .filter((item) =>
              item?.Main[1]
                ?.toLowerCase()
                .includes(filterText.toLocaleLowerCase())
                && item?.Main[1] !== "Animal Farming" && item?.Main[1] !== "Government And Political Sector"
            )
            .map((sector, index) => (
              <div
                key={index}
                className="flex gap-1 bg-white py-1 px-2 items-center rounded-full cursor-pointer hover:bg-gray-50 hover:scale-105 duration-200 border-[1px] border-[#d2d2d2]"
                // style={{
                //   boxShadow:
                //     "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                // }}
                onClick={() => submitSectorHandler(sector.Main[1])}
              >
                <img src={sector.Sectors_Url ?? factacy} className="w-4 h-4" />
                <p className="text-sm">{sector?.Main ? sector?.Main[1] : ""}</p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default SectorSearch;
