import React from "react";
import PrimaryButton from "../../components/PrimaryButton";
import video from "../../assets/Videos/Startup investors in india List 1.mp4";

const HomeSection = () => {
  return (
    <section
      className=" w-full justify-center flex flex-col [@media_screen_and_(min-width:810px)]:flex-row gap-4"
      style={{ minHeight: "calc(100vh - 8rem)" }}
    >
      <div className="py-8 lg:py-0 lg:w-1/2 flex flex-col lg:justify-center">
        <div className="text-5xl font-bold mb-4 flex flex-col">
          <span className="text-6xl py-2 text-primaryBlue">Fundraising</span>
          {/* <p><span className=" text-primaryBlue" >Close</span> your </p> */}
          <span> starts here… </span>
        </div>
        <h4 className="text-base mb-5">
        Start your Fundraising journey in 5 minutes.
        
        </h4>
        <PrimaryButton text={"Find Investors"} navigateRoute={"/action/find"} />
        
      </div>
      <div className="lg:flex-1 flex items-center justify-center">
        <div className=" overflow-hidden relative">
          <div className=" absolute top-0 left-0 right-0 bottom-0" style={{ "boxShadow": "#ffffff 30px 20px 30px 8px inset" }}></div>
          <div className=" absolute top-0 left-0 right-0 bottom-0" style={{ "boxShadow": "#ffffff -30px -20px 30px 8px inset" }}></div>
          <video src={video} width="1100" height="1200" autoPlay={true} loop muted className="" />
        </div>
      </div>
    </section>
  );
};

export default HomeSection;