import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Register from "./Register";
import Login from "./Login";
import Forget from "./Forget";


const Auth = ({ fromPopUp }) => {
  const [formType, setFormType] = useState("login");
  const token = sessionStorage.getItem("token");
  if (token) {
    return <Navigate to={"/action/payment"} />
  }


  return (
    <div className="overflow-y-auto rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] border-[#f1f1f1]">
      {formType === "login" && (<Login setFormType={setFormType} hideRegister={fromPopUp} />)}
      {formType === "register" && (<Register setFormType={setFormType} />)}
      {formType === "forget" && (<Forget setFormType={setFormType} />)}
    </div>
  );
};

export default Auth;