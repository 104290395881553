import React, { useEffect } from "react";
import HowItWork from "./HowItWork";
import BigNumbers from "./BigNumbers";
import HomeSection from "./HomeSection";
import BigPoints from "./BigPoints";
import Pricing from "./Pricing";
import FAQs from "./FAQs";
import axios from "axios";

const Homepage = () => {
  useEffect(() => {
    const pingLogin = async () => {
      await axios.get("https://factoq.com/backend/cdb/testRoute")
    }
    pingLogin()
  },[])
  return (
    <div className="px-6 pt-20 lg:px-20 [@media_screen_and_(min-width:1024px)]:container ">

      <div id="homeSection">
        <HomeSection />
      </div>
      <div id="bigNumbers">
        <BigNumbers />
      </div>
      <div id="bigPoints">
        <BigPoints />
      </div>
      <div id="howItWork">
        <HowItWork />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="faqs">
        <FAQs />
      </div>
    </div>
  );
};
export default Homepage;
