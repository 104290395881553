import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import closeLine from "../../assets/close-icon.svg";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import Loader from "../loader";
import CryptoJS from "crypto-js";
import useDebounce from "../../Hooks/useDebounce";

const Search = ({ type }) => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    "This might take 3-4 minutes.",
    "Looking for active investors only.",
    "Searching for sector and round specific investors. Hang tight!",
    "This is taking a bit longer, please wait!",
  ];

  const fetchSuggestions = async (input) => {
    if (input.length > 0) {
      try {
        const response = await axios(
          `https://factacyinsights.in/search?input=${input}`
        );

        const CompanySuggestions = (response.data || []).filter((item) => {
          return item.PROPERTY && item.PROPERTY.includes(type);
        });
        setSuggestions(CompanySuggestions);
      } catch {
        setSuggestions([]);
      }
    }
  };
  const optimizeFetchSuggestion = useDebounce(fetchSuggestions, 400);

  const handleChange = (input) => {
    setInput(input);
    optimizeFetchSuggestion(input);
  };

  const submitHandler = async (CIN, BNAME, LNAME) => {
    setMessage("");
    setInput("");
    setLoading(true);
    const encryptedURL = CryptoJS.AES.encrypt(CIN, "secret key 123").toString();
    const ID = uuidv4();
    const data = {
      brand_name: BNAME,
      mca_cin: CIN,
      id: ID,
      mca_company_name: LNAME,
      website_url: `https://www.factacyinsights.com/company_intelligence/${encryptedURL}`,
      email: sessionStorage.getItem("email")
        ? sessionStorage.getItem("email")
        : "no",
    };

    const makeRequest = async () => {
      try {
        const response = await axios.post(
          `https://factoq.com/inv/company`,
          data
        );

        setLoading(false);
        const storeable = { 
          name: LNAME, 
          type: type 
        };
        if (
          response.status === 200 &&
          response.data !== "No proposed investors found"
        ) {
          sessionStorage.setItem("uuid", ID);
          sessionStorage.setItem("company_legal_name", JSON.stringify(storeable));
          navigate("/action/confirm");
        } else {
          setMessage("No proposed investors found.");
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          setMessage("Oops! you got 404'ed.");

          setLoading(false);
        } else if (error.request) {
          // The request was made but no response was received
          setMessage("Oops! you got 404'ed.");

          setLoading(false);
        } else {
          // Something happened in setting up the request that triggered an Error
          setMessage("Oops! you got 404'ed.");

          setLoading(false);
        }
      }
    };
    makeRequest();
  };



  // setMessage("Oops! you got 404'ed.");
  const cycleText = () => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      setTextIndex(currentIndex);
      currentIndex = (currentIndex + 1) % texts.length;

      // If we have cycled through all texts, reset to the first text
      if (currentIndex === 0) {
        clearInterval(interval);
      }
    }, 20000);
  };

  useEffect(() => {
    cycleText();
  }, []);

  return (
    <div className="flex flex-col">
      <form
        className={`w-full bg-[#fff] rounded-md border-[1px] border-[#C0C0C0]`}
        required
        target="_blank"
      >
        <div className="flex justify-between h-12 gap-3 px-4 items-center">
          <svg
            width="23"
            height="21"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
              fill="#71717A"
            />
          </svg>

          <input
            placeholder="search your startup..."
            className="h-12 w-full appearance-none focus:outline-none "
            required
            type="text"
            onChange={(e) => handleChange(e.target.value.toUpperCase())}
            value={input}
          ></input>

          {input.length > 2 && (
            <button onClick={() => setInput("")}>
              <img src={closeLine} />
            </button>
          )}
        </div>
        <div className="">
          {handleChange && input.length > 2 && (
            <div className="bg-white w-full gap-2 h-full rounded-b-xl relative z-0">
              <ul
                className={` h-72 overflow-y-auto`}
                style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
              >
                {suggestions && suggestions.length > 0 ? (
                  suggestions.map((company, index) => (
                    <li
                      key={index}
                      className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 hover:bg-slate-50 flex gap-1"
                      onClick={(e) => {
                        e.preventDefault();
                        
                          submitHandler(
                            company.ID,
                            company.ALIAS[0],
                            company.NAME[0]
                          );
                       
                      }}
                    >
                      {company.ALIAS != "NULL" ? (
                        <p className="text-primaryBlue">
                          {company.ALIAS + " -"}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      <p>{company?.NAME}</p>
                    </li>
                  ))
                ) : (
                  <p className="sm:text-xs lg:text-base px-4 py-2 ">
                    Did not find your company or sector? Contact us at{" "}
                    <a href="mailto:sConnect@factacy.ai">Connect@factacy.ai</a>
                  </p>
                )}
              </ul>
            </div>
          )}
        </div>
      </form>
      <div className="flex flex-col grow">
        {loading && (
          <div className="flex flex-col items-center justify-center h-48">
            <Loader />
            <p className="text-sm font-semibold">{texts[textIndex]}</p>
          </div>
        )}
        {message && (
          <div className="flex flex-col items-center justify-center h-48">
            <p className="text-xl font-semibold text-center">
              {message}
              <br />{" "}
              <span className="text-xs ">
                Something broke, you might want to try again.
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// export default connect(null, { getCompany })(Search);
export default Search;
