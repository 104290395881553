import React, { useState } from "react";
import paymentPage from "../../assets/HowItWorks/payment.jpg";
import confirmPage from "../../assets/HowItWorks/confirmPage.png";
import findPage from "../../assets/HowItWorks/findPage.jpg";
import registerPage from "../../assets/HowItWorks/register.jpg";
import downloadPage from "../../assets/HowItWorks/download.jpg"

const HowItWork = () => {
  const colors = ["bg-blue", "bg-yellow", "bg-red", "bg-green", "bg-purple"];
  const bgImages = [findPage, confirmPage, registerPage, paymentPage, downloadPage];

  const [activeIndex, SetActiveIndex] = useState(0);
  const listOfSteps = [
    {
      title: "Find",
      description: "Locate your startup by typing in your company or brand name",
    },
    {
      title: "Confirm",
      description:
        "Our AI model will give you details like total number of investors, number of investors highly recommended, sample names of investors etc",
    },
    {
      title: "Register",
      description:
      "Register with your basic details on the portal to trigger the models to start preparing the file",
    },
    {
      title: "Pay",
      description: "You can choose to pay for a single download or multiple downloads for your startup ",
    },
    {
      title: "Download",
      description:
        'On confirmation, you can download the file saved for you. For multiple downloads, you can revisit again to download the latest file with updated investors',
    },
  ];
  return (
    <div className="mt-12 min-h-screen flex flex-col gap-4 justify-center items-center">
      <h1 className=" text-5xl text-center font-bold lg:w-2/3">
      Identify Active Investors <br/> most relevant to your startup
      </h1>
      <p className=" text-base text-center lg:w-2/3">
      Pitch only to investors who are currently investing actively in your business sector. Get your own list of potential investors for your business in 5 easy steps
      </p>
      <div className=" w-full flex gap-12 p-4 md:p-10 lg:p-16">
        <div className={`p-16 flex-1 rounded-[1rem] hidden [@media_screen_and_(min-width:976px)]:block relative overflow-hidden`}>
          <img
            style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            src={[bgImages[0]]}
            alt="img"
            className={`transition duration-1000 w-11/12 absolute  shadow-lg ${activeIndex === 0 ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" : "left-[-150%] top-1/2 -translate-x-1/2 -translate-y-1/2"} transition-all duration-1000`}
          />
          <img
            style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            src={[bgImages[1]]}
            alt="img"
            className={`transition duration-1000 w-11/12 absolute  shadow-lg ${activeIndex === 1 ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" : "left-[-150%] top-1/2 -translate-x-1/2 -translate-y-1/2"} transition-all duration-1000`}
          />
          <img
            style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            src={[bgImages[2]]}
            alt="img"
            className={`transition duration-1000 w-11/12 absolute  shadow-lg ${activeIndex === 2 ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" : "left-[-150%] top-1/2 -translate-x-1/2 -translate-y-1/2"} transition-all duration-1000`}
          />
          <img
            style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            src={[bgImages[3]]}
            alt="img"
            className={`transition duration-1000 w-8/12 absolute  shadow-lg ${activeIndex === 3 ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" : "left-[-150%] top-1/2 -translate-x-1/2 -translate-y-1/2"} transition-all duration-1000`}
          />
          <img
            style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            src={[bgImages[4]]}
            alt="img"
            className={`transition duration-1000 w-11/12 absolute  shadow-lg ${activeIndex === 4 ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" : "left-[-150%] top-1/2 -translate-x-1/2 -translate-y-1/2"} transition-all duration-1000`}
          />
        </div>
        <div className="flex-1">
          {listOfSteps.map((item, index) => (
            <div
              key={index}
              className={`flex gap-3 ${listOfSteps.length - 1 !== index ? "min-h-24" : ""
                }`}
            >
              <div className=" relative">
                <span className="h-8 w-8 bg-primaryBlue rounded-full flex items-center justify-center text-[#fff] relative z-10">
                  {index + 1}
                </span>
                {listOfSteps.length - 1 !== index && (
                  <div className=" w-1 bg-blue h-full absolute top-0 right-1/2 translate-x-1/2 z-0"></div>
                )}
              </div>
              <div>
                <h2
                  className={`text-[0.9rem] leading-[0.9rem] font-extrabold uppercase cursor-pointer w-fit ${activeIndex === index ? " lg:text-primaryBlue" : ""
                    }`}
                  onMouseEnter={(e) => SetActiveIndex(index)}
                  onMouseLeave={(e) => { }}
                >
                  {item.title}
                </h2>
                <p className=" text-[0.78rem]"
                  onMouseEnter={(e) => SetActiveIndex(index)}
                  onMouseLeave={(e) => { }}>{  item.description}
                  </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWork;