import React from "react";
import PrimaryButton from "../../components/PrimaryButton";
import refresIcon from "../../assets/refresh.svg";
import activeInvestorsIcon from "../../assets/activeInvestors.svg";
import customizationIcon from "../../assets/customization.svg";
import investorsTeam from "../../assets/investors-team.svg"

const BigPoints = () => {
  return (
    <section className=" flex flex-col min-h-screen justify-center items-center gap-6 pb-6">
      <div className=" mt-16 flex flex-col justify-center items-center lgg:flex-row gap-6 pb-6">
        <div className=" w-full lgg:flex-1">
          <div
            className="sm:w-full rounded-xl min-h-[300px] bg-white p-8 flex flex-col  items-start gap-4"
            style={{
              "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <img
              src={activeInvestorsIcon}
              className=" w-16"
              alt="activeIcons"
            />
            <h2 className="text-[#232323] text-[1.5rem] font-bold">
              New and Active Investors Only
            </h2>
            <p className="text-[#A1A1AA]">
              Most Active Investors spotted from over 10,000 investment deals in the last 18-24 months
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-6">
          <div
            className="sm:w-full rounded-xl min-h-[300px] bg-white p-8 flex flex-col items-start gap-4"
            style={{
              "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <img
              src={customizationIcon}
              className=" w-16"
              alt="activeIcons"
            />
            <h2 className="text-[#232323]  text-[1.5rem] font-bold">
              Investors relevant to your startup
            </h2>
            <p className="text-[#A1A1AA]">
              Next Gen recommendation logics analyze your startups sector and stage and match them with investment history of the investors
            </p>
          </div>
          <div
            className="sm:w-full rounded-xl min-h-[320px] bg-white  p-8 flex flex-col items-start gap-4"
            style={{
              "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <img src={investorsTeam} className=" w-16" alt="activeIcons" />
            <h2 className="text-[#232323]  text-[1.5rem] font-bold">Made by Founders and Investors </h2>
            <p className="text-[#A1A1AA]">
              AI models, trained on founders and Investors experiences, prepare curated list of Investors who are most likely to partner with your startup
            </p>
          </div>
        </div>
        <div className=" w-full lgg:flex-1">
        <div
            className="sm:w-full rounded-xl min-h-[300px] bg-white  p-8 flex flex-col items-start gap-4"
            style={{
              "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <img src={refresIcon} className=" w-16" alt="activeIcons" />
            <h2 className="text-[#232323]  text-[1.5rem] font-bold">Fresh List, every week</h2>
            <p className="text-[#A1A1AA]">
              Our AI models pick up new investors and deals. So, you get a refreshed list of every time you download
            </p>
          </div>
        </div>
      </div>
      <PrimaryButton text={"Find Investors"} navigateRoute={"/action/find"} />
    </section>
  );
};
export default BigPoints;