import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar";
import Homepage from "./pages/homepage";
import Resetpassword from "./pages/Resetpassword.jsx";
import Footer from "./components/footer/Footer";
import ActionPage from "./pages/actionPage";
import PopUp from "./components/PopUp";
import PrivacyPolicy from "./pages/footer-pages/PrivacyPolicy";
import TermsAndConditions from "./pages/footer-pages/TermsAndConditions";
import CancellationAndRefund from "./pages/footer-pages/CancellationAndRefund";
import ContactUs from "./pages/footer-pages/ContactUs";
import ShippingAndDelivery from "./pages/footer-pages/ShippingAndDelivery";
import Enquire from "./pages/enquireNow";
import Spotlight from "./pages/Spotlight/Spotlight.jsx";

function App() {
  const location = useLocation();
  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "http://172.206.28.154/js/container_Xn2vjyk6.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div className="App smoothScroll h-screen ">
      <div className="relative ">
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/action/*" element={<ActionPage />} />
          <Route path="/investor-spotlight/:investorName/:investorId" element={<Spotlight />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="/cancellation-and-refund"
            element={<CancellationAndRefund />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
          <Route
            path="/shipping-and-delivery"
            element={<ShippingAndDelivery />}
          />
          <Route path="/enquire" element={<Enquire />} />
        </Routes>
        {location.pathname === "/" && <Footer />}
      </div>
    </div>
  );
}

export default App;
