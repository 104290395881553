import React, { useState } from 'react';
import { FAQs } from '../../Utils/FAQs';
import FAQItem from './FAQItem';

const FAQsComponent = () => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const { list, title } = FAQs;

    return (
        <div className=' min-h-screen '>
            <h1 className=' text-4xl font-bold text-center mt-8'>{title}</h1>
            <div className=' mt-6 mb-12 flex flex-col items-center gap-8 lg:px-16'>
                {list.map((item, index) => (<FAQItem
                    key={index}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    item={item}
                />))}
            </div>

        </div >
    )
}

export default FAQsComponent;