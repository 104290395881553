import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

const useLogin = () => {
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    // const [res, setRes] = useState('');
    const Navigate = useNavigate();
    const navigationRoute = state?.form === "confirmationPage" ? "/action/payment" : "/action/find";

    const login = async (values) => {

        setLoading(true);
        const res = axios.post("https://factacyinsights.in/inv_login", {
            email: values.email,
            password: values.password,
        }).then((res) => {
            if (res?.data?.token) {
                sessionStorage.setItem('token', res?.data?.token);
                sessionStorage.setItem('email', values.email);
                Navigate(navigationRoute);
            } else if (res.status === 400) {
                setError("Incorrect Email or Password!")
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setError(err);
        });
    }

    return [loading, error, login];
}

export default useLogin;