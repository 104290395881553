import React from "react";
import SideBar from "./sideBar";
import { Route, Routes } from "react-router-dom";
import Find from "../findCompany.jsx/index.jsx";
import Confirmation from "../confirmation/index.jsx";
import Auth from "../register/index.jsx";
import Payment from "../payment/index.jsx";
import Download from "../download/index.jsx";

const ActionPage = () => {
  return (
    <div className=" w-screen">
      <div className="flex flex-col w-full ">
        <div className="pt-20 px-6 lg:px-12 overflow-hidden lg:pt-40 lg:fixed lg:w-1/3 lg:left-0 lg:h-screen lg:flex-col lg:items-end shadow-xl lg:flex">
          <SideBar />
        </div>
        <div className="fadeIncrease p-4 w-screen static lg:fixed lg:w-2/3  lg:right-0 lg:overflow-y-auto lg:h-full lg:bg-[#f6f6f6] lg:pt-32 lg:pb-6 lg:px-20">
          <Routes>
            <Route element={<Find />} path="/find" />
            <Route element={<Confirmation />} path="/confirm" />
            <Route element={<Auth />} path="/register" />
            <Route element={<Payment />} path="/payment" />
            <Route element={<Download />} path="/download" />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ActionPage;