import React, { useEffect } from 'react';
import PrimaryButton from '../../components/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import Blog from './Blog';
import Loader from '../../components/loader';
import { INVESTOR_BLOG } from '../../constent/api';

const Spotlight = () => {
    const { investorId } = useParams();
    const [loading, error, data, blogFetcher] = useFetch();
    const Navigate = useNavigate();

    useEffect(() => {
        if (investorId) {
            blogFetcher(INVESTOR_BLOG + investorId);
            const linkTag = document.createElement("link");
            linkTag.setAttribute("rel", "canonical");
            linkTag.href = window.location.href;
            document.head.appendChild(linkTag);
        }
    }, [investorId])
    return (
        <div className=" w-screen  min-h-screen flex gap-4 ">
            <div className=' w-1/3 hidden lg:flex justify-center items-center '>
                <div className='flex flex-col'>
                    <div className="text-5xl font-bold mb-4 flex flex-col">
                        <span className="text-6xl py-2 text-primaryBlue">Fundraising</span>
                        <span> starts here… </span>
                    </div>
                    <h4 className="text-base mb-5">
                        Start your Fundraising journey in 5 minutes.

                    </h4>
                    <PrimaryButton text={"Find Investors"} navigateRoute={"/action/find"} handleButtonClick={() => Navigate('/action/find#pricing')} />
                </div>
            </div>
            <div className="fadeIncrease max-h-screen overflow-scroll p-4 pt-12 w-screen static lg:fixed lg:w-2/3  lg:right-0 lg:overflow-y-auto lg:h-full lg:bg-[#f6f6f6] lg:pt-[4rem] lg:pb-4 lg:px-8">
                {
                    loading ? (<div className=' w-full flex justify-center'><Loader /></div>) : (

                        <>
                            {
                                data?.length > 0 && (<Blog blog={data[0]} />)
                            }
                            {
                                (data?.length === 0 || error) && (
                                    <div className=' flex items-center justify-center'>
                                        <h1 className=' text-xl '>Maybe you are at broken url</h1>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>

        </div>
    );
}

export default Spotlight;



{/* <div className=" w-screen min-h-screen">
            <div className="flex flex-col w-full min-h-screen">
                <div className="hidden lg:fixed lg:left-0 lg:right-0 lg:top-0 lg:bottom-0 lg:w-1/3 lg:px-12 lg:pt-40 lg:min-h-screen shadow-xl bg-red">
                    <div className=" flex flex-col lg:justify-center border-2 border-r-green">
                        <div className="text-5xl font-bold mb-4 flex flex-col">
                            <span className="text-6xl py-2 text-primaryBlue">Fundraising</span>
                            // <p><span className=" text-primaryBlue" >Close</span> your </p>
                            <span> starts here… </span>
                        </div>
                        <h4 className="text-base mb-5">
                            Start your Fundraising journey in 5 minutes.

                        </h4>
                        <PrimaryButton text={"Find Investors"} navigateRoute={"/action/find"} handleButtonClick={()=>Navigate('/action/find#pricing')}/>

                    </div>
                </div>
                <div className="fadeIncrease p-4 pt-12 w-screen static lg:fixed lg:w-2/3  lg:right-0 lg:overflow-y-auto lg:h-full lg:bg-[#f6f6f6] lg:pt-16 lg:pb-4 lg:px-8">
                    {
                        loading ? (<div className=' w-full flex justify-center'><Loader /></div>) : (

                            <>
                                {
                                    data?.length > 0 && (<Blog blog={data[0]} />)
                                }
                                {
                                    (data?.length === 0 || error) && (
                                        <div className=' flex items-center justify-center'>
                                            <h1 className=' text-xl '>Maybe you are at broken url</h1>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div> */}
