import React from "react";
import Searchbar from "../../components/searchbar";
import { useState, useEffect } from "react";
import Pricing from "../homepage/Pricing";
import { useLocation } from "react-router-dom";

import SectorSearch from "../../components/searchbar/sectorSearch";

const Find = () => {
  const [selectedSection, setSelectedSection] = useState("pricing");
  const location = useLocation()
  const [existingMetaTags, setExistingMetaTags] = useState({
    description: document
      .querySelector('meta[name="description"]')
      .getAttribute("content"),
    keywords: document
      .querySelector('meta[name="keywords"]')
      .getAttribute("content"),
    title: document.querySelector('meta[name="title"]').getAttribute("content"),
  });



  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Download personalized list of verified investors tailored for your Company. Get investor profiles featuring contact details, geographic location, industry specialization, social accounts and much more."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "Find investors for my startup, Find angle investors, Find Venture capitalists, Invest into startups, Fundraising platform, Get personalized investors, Industry based investor search, Round based investor search, Find relevant investors, Get Investor details,Investor information"
      );
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", "Discover Investors for your startup");

    return () => {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", existingMetaTags.description);

      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", existingMetaTags.keywords);

      document
        .querySelector('meta[name="title"]')
        .setAttribute("content", existingMetaTags.title);
    };
  }, []);



  useEffect(() => {
    // Update the URL hash when the selected section changes
    window.location.hash = `#${selectedSection}`;
  }, [selectedSection]);

  const handleButtonClick = (text) => {
    setSelectedSection(text.toLowerCase());
  };

  return (
    <div className=" fadeIncrease overflow-y-auto rounded-xl shadow-xl items-center px-8  min-h-[500px] bg-[#fff] border-[1px] border-[#f1f1f1]">
      {location.hash === "#pricing" && (
        <div>
          <Pricing handleButtonClick={handleButtonClick} />
        </div>
      )}

      {location.hash === "#startup" && (
        <div className="flex flex-col gap-6 py-8 fadeIncrease" id="company">
          <h1 className="text-3xl font-bold font-sans ">
            Search investors for your{" "}
            <span className="text-primaryBlue"> startup. </span>
          </h1>
          <div className="w-full">
            <Searchbar type='Company' />
          </div>
        </div>
      )}

      {location.hash === "#sector" && (
        // <div className="flex flex-col gap-6 py-8 fadeIncrease" id="sector">
        //   <h1 className="text-3xl font-bold font-sans ">
        //     Search investors for your{" "}
        //     <span className="text-primaryBlue"> Sector </span>
        //   </h1>
        //   <div className="w-full">
        //     <Searchbar type='Sector' />
        //   </div>
        // </div>
        <div className="flex flex-col gap-6 py-8 fadeIncrease" id="company">
        <h1 className="text-3xl font-bold font-sans ">
          Search investors for your{" "}
          <span className="text-primaryBlue"> sector. </span>
        </h1>
        <div><SectorSearch type="Sector" /></div>
        </div>
      )}
    </div>
  );
};

export default Find;
