import React from 'react'

const CancellationAndRefund = () => {
  return (
    <div className='py-4 px-4 mb-4 lg:py-28 lg:px-32 lg:mb-20 mt-12 lg:mt-0 h-max font-roboto'>
    <div className='flex flex-col gap-3 bg-white p-8 bg-opacity-50 rounded-3xl' style={{boxShadow: "0px 0px 50.465946197509766px 0px rgba(0, 0, 0, 0.08"}}>
    <h1 className='text-4xl font-bold'>Refund and Cancellation Policy</h1>
        <p>At Factacy, we strive to provide our users with accurate and valuable investor data. We understand that occasionally circumstances may arise where you need to cancel your purchase or request a refund. Please review our policy below for more information:</p>

        <h3>Cancellation Policy:</h3>
        <ul className='list-decimal px-4'>
            <li><strong>Subscription Services:</strong> If you have subscribed to our services on a recurring basis (e.g., monthly or yearly), you may cancel your subscription at any time. Upon cancellation, your access to the subscribed services will continue until the end of the current billing period. No further charges will be applied after the cancellation date.</li>
            <li><strong>One-Time Purchases:</strong> For one-time purchases of individual reports or data sets, cancellation is not applicable once the product has been delivered and accessed.</li>
        </ul>

        <h3>Refund Policy:</h3>
        <ul className='list-decimal px-4'>
            <li><strong>Quality Assurance:</strong> We take pride in the quality and accuracy of our investor data reports. If you encounter any inaccuracies or discrepancies in the data provided, please contact our support team within 48 hours of purchase. We will investigate the issue promptly and provide a resolution, which may include a refund if deemed necessary.</li>
            <li><strong>Technical Issues:</strong> In the event of technical issues preventing access to purchased reports or services, please reach out to our support team for assistance at <a href="mailto:connect@factacy.ai">connect@factacy.ai</a>.</li>
            <li><strong>Unauthorized Purchases:</strong> If you believe that a purchase was made on your account without your authorization, please notify us immediately. We will investigate the matter and take appropriate action, which may include issuing a refund.</li>
        </ul>

        <h3>Refund Process:</h3>
        <ul className='list-decimal px-4'>
            <li><strong>Contact Support:</strong> To request a refund or report an issue, please contact our support team via email at <a href="mailto:connect@factacy.ai">connect@factacy.ai</a>.</li>
            <li><strong>Provide Details:</strong> When contacting support, please provide relevant details such as your order number, purchase date, and a brief description of the issue.</li>
            <li><strong>Resolution:</strong> Our support team will review your request and provide a resolution within 5 business days. Refunds, if applicable, will be processed using the original payment method.</li>
        </ul>

        <h3>Exceptions:</h3>
        <ul className='list-decimal px-4'>
            <li><strong>Customized Reports:</strong> Refunds are generally not available for customized or personalized reports that have been tailored to specific user requirements, unless there are significant quality issues.</li>
        </ul>

        <p>Factacy Pvt. Ltd. reserves the right to update or modify this refund and cancellation policy at any time without prior notice. By using our services, you agree to abide by the terms outlined in this policy.</p>

        <p>If you have any questions or concerns regarding our refund and cancellation policy, please don't hesitate to contact us at <a className='underline' href="mailto:connect@factacy.ai">connect@factacy.ai</a>.</p>
    </div>
    </div>
  )
}

export default CancellationAndRefund