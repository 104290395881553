import React, { useState } from "react";
// import factacyLogo from "../../assets/factacy-logo-1.png";
import factacyLogo from "../../assets/Icons/Artboard 1.png";
import menuIcon from "../../assets/menu.svg";
import closeIcon from "../../assets/close.svg";
import PrimaryButton from "../PrimaryButton";
import { useNavigate } from "react-router-dom";
import PopUp from "../PopUp";

const Navbar = () => {
  const mobileViewMaxPx = 976;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const Navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);

  return (
    <nav className="h-max backdrop-blur z-40 w-full fixed top-0">
      {popUp && !sessionStorage.getItem("token") && (
        <PopUp setPopUp={setPopUp} />
      )}

      <div className=" relative px-6 py-4 lg:px-20 ">
        <div className="flex justify-between  items-center">
          <img
            src={factacyLogo}
            className="h-6 lg:h-10 cursor-pointer"
            onClick={() => Navigate("/")}
          />

          <div className={`hidden [@media_screen_and_(min-width:880px)]:flex gap-8 text-sm items-center font-semibold`}>
            <button
              className={`${window.location.pathname === "/" ? "visible" : "invisible"
                } py-1 px-2 lg:px-4 rounded-full text-sm font-normal text-buttonCTA hover:border-buttonCTA border-[1.4px] w-fit cursor-pointer hover:bg-buttonCTA hover:text-[#ffffff]`}
              onClick={() => Navigate("/action/find")}
            >
              Find Investors
            </button>
            <a
              className={`${window.location.pathname === "/" ? "visible" : "invisible"
                }  cursor-pointer`}
              onClick={() => {
                document.getElementById("howItWork") &&
                  document.getElementById("howItWork").scrollIntoView();
              }}
            >
              How it works
            </a>
            <a
              className={`${window.location.pathname === "/" ? "visible" : "invisible"
                }  cursor-pointer`}
              onClick={() => {
                document.getElementById("pricing") &&
                  document.getElementById("pricing").scrollIntoView();
              }}
            >
              Pricing
            </a>
            <button className={`${window.location.pathname !== "/" ? "visible" : "hidden"
              }  cursor-pointer`} onClick={() => Navigate("/")}>
              Home
            </button>
            <button className="cursor-pointer" onClick={() => Navigate("/action/download")}>
              My downloads
            </button>
            {/* <button className="cursor-pointer" onClick={() => Navigate("/investor-spotlight")}>
              Investor Spotlight
            </button> */}
            {sessionStorage.getItem("token") ? (
              <button
                className="px-4 py-2 text-[#fff] bg-primaryBlue cursor-pointer rounded-full"
                onClick={() => {
                  sessionStorage.removeItem("token");
                  sessionStorage.removeItem("email");
                  sessionStorage.removeItem("mca_legal_name");
                  sessionStorage.removeItem("uuid")

                  window.location.reload()
                }}
              >
                Logout
              </button>
            ) : (
              <button
                className="px-4 py-2 text-[#fff] bg-primaryBlue cursor-pointer rounded-full"
                onClick={() => setPopUp(true)}
              >
                Login
              </button>
            )}
          </div>
          <img
            src={menuIcon}
            className={` block [@media_screen_and_(min-width:880px)]:hidden cursor-pointer h-5`}
            alt="menueIcon"
            onClick={() => setIsSidebarOpen(true)}
          />
        </div>
        <div
          style={{ height: "calc(100vh + 8rem)" }}
          className={`absolute top-0 ${isSidebarOpen ? " right-0" : " right-[-100%]"
            } w-72 bg-blue flex flex-col items-end gap-4 text-sm font-semibold p-8 duration-200`}
        >
          <img
            src={closeIcon}
            className=" cursor-pointer h-7"
            alt="menueIcon"
            onClick={() => setIsSidebarOpen(false)}
          />
          {sessionStorage.getItem("token") ? (
            <button
              className="px-4 py-2 text-[#fff] bg-primaryBlue cursor-pointer rounded-full"
              onClick={() => {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("mca_legal_name");
                sessionStorage.removeItem("uuid")

                window.location.reload();
                setIsSidebarOpen(false);
              }}
            >
              Logout
            </button>
          ) : (
            <button
              className="px-4 py-2 text-[#fff] bg-primaryBlue cursor-pointer rounded-full"
              onClick={() => { setPopUp(true); setIsSidebarOpen(false); }}
            >
              Login
            </button>
          )}
          
          <a
            onClick={() => {
              setIsSidebarOpen(false);
              document.getElementById("pricing") &&
                document.getElementById("pricing").scrollIntoView();
            }}
          >
            Pricing
          </a>
          <p
            onClick={() => {
              setIsSidebarOpen(false);
              Navigate("/");
            }}
          >
            Home
          </p>
          <a
            to="/"
            onClick={() => {
              setIsSidebarOpen(false);
              document.getElementById("howItWork") &&
                document.getElementById("howItWork").scrollIntoView();
            }}
          >
            How it works
          </a>
          <button className="cursor-pointer" onClick={() => { Navigate("/action/download"); setIsSidebarOpen(false); }}>
            My downloads
          </button>

          {/* <button className="cursor-pointer" onClick={() => { Navigate("investor-spotlight"); setIsSidebarOpen(false); }}>
            Investor Spotlight
          </button> */}


          <div onClick={() => setIsSidebarOpen(false)}>
            <PrimaryButton
              text={"Find Investors"}
              navigateRoute={"/action/find"}
            />
          </div>
        </div>

      </div>
    </nav >
  );
};

export default Navbar;