import React, { useEffect, useState } from "react";
import lock from "../../assets/lock.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import excel from "../../assets/factacy-investor-template.xlsx";

const Confirmation = () => {
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState([]);
  const [sectorMetaData, setSectorMetaData] = useState([]);
  const [message, setMessage] = useState("");
  const uuid = sessionStorage.getItem("uuid");
  const name = JSON.parse(sessionStorage.getItem("company_legal_name"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMetaData = async () => {
      setLoading(true);
      try {
        if (uuid != null) {
          if (name.type === "Company") {
            const response = await axios.get(
              `https://factoq.com/backend/cdb/proposed_investor?sterm=${uuid}`
            );
            if (Array.isArray(response.data) > 0) {
              setMetaData(response.data);
            }
          } else if ((name.type = "Sector")) {
            const response = await axios.get(
              `https://factoq.com/backend/blob/confirmsector?sector=${name.name}`
            );
            if (Array.isArray(response.data) > 0) {
              setSectorMetaData(response.data);
            }
          } else {
            setMessage("No proposed investors");
            setMetaData([]);
          }
        } else {
          setMessage(`Please search for a company. `);
        }
        setLoading(false);
      } catch (error) {
        if (error.response) {
          setMessage("Oops! you got 404'ed.");
        } else if (error.request) {
          setMessage("Oops! you got 404'ed.");
        } else {
          setMessage("Oops! you got 404'ed.");
        }
        setLoading(false);
      }
    };
    fetchMetaData();
  }, []);

  const downloadTemplate = () => {
    fetch("../../assets/factacy-investor-template.xlsx", {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "factacy-investors-template.xlsx");

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up: remove the link from the document body
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error fetching the Excel file:", error));
  };

  return (
    <div className="fadeIncrease rounded-xl shadow-xl p-8 min-h-[500px] bg-[#fff] border-[1px] border-[#f1f1f1] ">
      {loading && (
        <div className="flex flex-col items-center">
          <Loader />{" "}
        </div>
      )}
      {metaData &&
        metaData.length > 0 &&
        metaData.map((meta, i) => (
          <React.Fragment key={i}>
            <div
              className="flex flex-col items-end relative bottom-3 cursor-pointer"
              onClick={() => downloadTemplate()}
            >
              <p className="text-[#505050] text-xs">Download Template</p>
            </div>
            <div className="flex flex-col lg:flex-row justify-around gap-8 pb-4 border-b-[1px] border-[#bebebe]">
              <div className="flex flex-col w-full lg:w-1/2 items-center">
                <p className="text-lg font-bold">
                  {
                    JSON.parse(sessionStorage.getItem("company_legal_name"))
                      .name
                  }
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-lg font-bold">
                  {meta && meta.counts && meta.counts.num_investors}
                </p>
                <p className="lg:text-sm text-xs text-[#505050]">
                  Round & Sector-wise
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-lg font-bold">
                  {meta && meta.counts && meta.counts.highly_recom}
                </p>
                <p className="lg:text-sm text-xs text-[#505050]">
                  Highly Recommended
                </p>
              </div>
            </div>
            <div className="w-full mt-5 border-[1px] border-[#e9e8e8] rounded-lg  overflow-x-auto overflow-y-hidden">
              <table className="table-auto w-full text-start text-xs -mb-12">
                <thead>
                  <tr>
                    <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f] rounded-tl-lg">
                      Investor
                    </th>
                    <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                      Last Round
                    </th>
                    <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                      Last Sector
                    </th>
                    <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f] rounded-tr-lg">
                      Last Deal
                    </th>
                    <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                      Website
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(meta.proposed_Investor).map((element, i) => (
                    <tr key={i}>
                      <td className="p-4">{element}</td>
                      <td className="p-4">
                        {
                          meta.proposed_Investor[element]
                            .latest_investment_round
                        }
                      </td>
                      <td className="p-4">
                        {
                          meta.proposed_Investor[element]
                            .latest_investment_sector
                        }
                      </td>
                      <td className="p-4">
                        {meta.proposed_Investor[element].latest_investment_date}
                      </td>
                      <td className="p-4">
                        {meta.proposed_Investor[element].website}
                      </td>
                    </tr>
                  ))}
                  <tr className="">
                    <td className="text-center -h-10" colSpan="5">
                      <div className="flex flex-col items-center justify-between p-4 bg-gradient-to-t from-[#fff] to-transparent z-40 h-20 bottom-16 relative">
                        <img
                          src={lock}
                          className="w-5 h-5 absolute bottom-0 drop-shadow-xl"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="pt-5 flex flex-col items-center gap-4">
              <div>
                <p className="text-base font-semibold">
                  Download the complete file, including: website, contacts,
                  sector, round etc. in the next step.
                </p>
              </div>

              <button
                className="bg-primaryBlue text-[#fff] px-4 py-2 rounded-full"
                onClick={() =>
                  navigate("/action/register", {
                    state: { form: "confirmationPage" },
                  })
                }
              >
                Next
              </button>
              <p className="text-[10px]">
                Not happy with the result, please get in touch with us,{" "}
                <a className="underline" href="mailto:connect@factacy.ai">
                  connect@factacy.ai
                </a>
              </p>
            </div>
          </React.Fragment>
        ))}

      {sectorMetaData && sectorMetaData.length > 0 && (
        <React.Fragment>
          <div className="flex flex-col w-full ">
            <div className="flex w-full justify-between">
          <p className="text-lg font-bold">
              {JSON.parse(sessionStorage.getItem("company_legal_name")).name}
            </p>
            {sectorMetaData[0].map((meta, i) => (
              <div className="flex flex-col items-center" key={i}>
                <p className="text-lg font-bold">{meta && meta.investors_count}</p>
                <p className="lg:text-sm text-xs text-[#505050]">
                  Number of Investors
                </p>
              </div>
            ))}
            </div>
          </div>
          <div className="w-full mt-5 border-[1px] border-[#e9e8e8] rounded-lg  overflow-x-auto overflow-y-hidden">
            <table className="table-auto w-full text-start text-xs -mb-12">
              <thead>
                <tr>
                  <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f] rounded-tl-lg">
                    Investor
                  </th>
                  <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                    Last Round
                  </th>
                  <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                    Last Sector
                  </th>
                  <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f] rounded-tr-lg">
                    Last Deal
                  </th>
                  <th className="p-4 bg-[#f1f1f1] text-start text-[#5f5f5f]">
                    Website
                  </th>
                </tr>
              </thead>
              <tbody>
                {sectorMetaData[1]?.slice(0, 3).map((meta, i) => (
                  <tr key={i}>
                    <td className="p-4">{meta.Corrected_Investors[1]}</td>
                    <td className="p-4">{meta.Latest_Deal_Round}</td>
                    <td className="p-4">{meta.Latest_Deal_Sector}</td>
                    <td className="p-4">{meta.Latest_Deal_Date}</td>
                    <td className="p-4">{meta.Website}</td>
                  </tr>
                ))}
                <tr className="">
                  <td className="text-center -h-10" colSpan="5">
                    <div className="flex flex-col items-center justify-between p-4 bg-gradient-to-t from-[#fff] to-transparent z-40 h-20 bottom-16 relative">
                      <img
                        src={lock}
                        className="w-5 h-5 absolute bottom-0 drop-shadow-xl"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pt-5 flex flex-col items-center gap-4">
            <div>
              <p className="text-base font-semibold">
                Download the complete file, including: website, contacts,
                sector, round etc. in the next step.
              </p>
            </div>

            <button
              className="bg-primaryBlue text-[#fff] px-4 py-2 rounded-full"
              onClick={() =>
                navigate("/action/register", {
                  state: { form: "confirmationPage" },
                })
              }
            >
              Next
            </button>
            <p className="text-[10px]">
              Not happy with the result, please get in touch with us,{" "}
              <a className="underline" href="mailto:connect@factacy.ai">
                connect@factacy.ai
              </a>
            </p>
          </div>
        </React.Fragment>
      )}
      {message && (
        <div className="flex flex-col items-center justify-center h-48">
          <p className="text-xl font-semibold text-center">
            {message}
            <br />{" "}
            <span className="text-xs ">
              Something broke, you might want to try again.
            </span>
          </p>
          <button
            className="m-3 px-3 py-1 font-semibold text-buttonCTA border-buttonCTA border-2 rounded-full hover:text-[#fff] hover:bg-buttonCTA"
            onClick={() => navigate("/action/find")}
          >
            Find Investors
          </button>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
