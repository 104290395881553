import React from "react";
import insights from "../../assets/Insights.jpg"

const BigNumbers = () => {
  return (
    <section className="py-12 flex flex-col gap-5 items-center">
      <h1 className=" text-xl lg:text-4xl font-medium text-center  mb-4">
      Next Gen AI engines scan through millions of sources to identify and match the potential investors to your startup.
      </h1>
      <div className=" flex w-full flex-col pt-10 gap-6 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
        <div className=" flex flex-[2]">
          <div className="flex-1 flex justify-center flex-col lg:border-r-[1px] border-[#d1d5db] items-center">
            <p className=" text-primaryBlue text-3xl font-bold">2M</p>
            <p className=" text-base">Webpages</p>
          </div>
          <div className="flex-1 px-4  flex flex-col justify-center items-center lg:border-r-[1px] border-[#d1d5db]">
            <p className=" text-primaryBlue text-3xl font-bold">10K</p>
            <p className=" text-base">Investors</p>
          </div>
        </div>
        <div className=" flex flex-[2]">
          <div className="flex-1 px-4  flex flex-col justify-center items-center lg:border-r-[1px] border-[#d1d5db] ">
            <p className=" text-primaryBlue text-3xl font-bold">8K</p>
            <p className=" text-base"> Deals</p>
          </div>
          <div className="flex-1 px-4  flex flex-col justify-center items-center ">
            <p className=" text-primaryBlue text-3xl font-bold">100K</p>
            <p className=" text-base">Startups</p>
          </div>
        </div>
      </div>

      <div className="flex items-center text-base">
        <p>Powered by</p>
        <a href="https://www.factacyinsights.com" target="_blank"><img className="w-56 grayscale cursor-pointer" src={insights} /></a>
      
      </div>
    </section>
  );
};

export default BigNumbers;

// ---------------------------- version: 2 ----------------------------

// import React from "react";

// const BigNumbers = () => {
//   return (
//     <section className="py-12 flex flex-row gap-8 min-h-screen">
//       <div
//         className=" flex-1 flex items-center justify-center flex-wrap"
//         // style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
//       >
//         <div className="flex-1 flex justify-center flex-col border-[#d1d5db] items-center min-w-48">
//           <p className=" text-primaryBlue text-3xl font-bold">7500+</p>
//           <p className=" text-base">Deals</p>
//         </div>
//         <div className="flex-1 px-4  flex flex-col justify-center items-center min-w-48 border-[#d1d5db]">
//           <p className=" text-primaryBlue text-3xl font-bold">10k+</p>
//           <p className=" text-base">Investors across</p>
//         </div>
//         <div className="flex-1 px-4  flex flex-col justify-center items-center min-w-48 border-[#d1d5db] ">
//           <p className=" text-primaryBlue text-3xl font-bold">50+</p>
//           <p className=" text-base"> sectors</p>
//         </div>
//         <div className="flex-1 px-4  flex flex-col justify-center items-center min-w-48 border-[#d1d5db] ">
//           <p className=" text-primaryBlue text-3xl font-bold">150k+</p>
//           <p className=" text-base">sources</p>
//         </div>
//         <div className="flex-1 px-4 flex flex-col justify-center items-center min-w-48">
//           <p className=" text-base">Refreshing every</p>
//           <p className=" text-primaryBlue text-3xl font-bold">
//             60<span className=" text-base">s</span>
//           </p>
//         </div>
//       </div>
//       <div className="flex-1 flex items-center justify-center">
//         <h1 className="text-4xl font-medium text-center mb-4">
//           Ai generated curated list of targeted investors for your startup.
//         </h1>
//       </div>
//     </section>
//   );
// };

// export default BigNumbers;

// ---------------------------- version: 3 ----------------------------

// import React from "react";

// const BigNumbers = () => {
//   return (
//     <section className="py-12 flex flex-col gap-8 items-center">
//       <h1 className="text-4xl font-bold text-center lg:w-2/3 mb-4">
//         Ai generated curated list of <span className="text-primaryBlue">Targeted</span> investors for your startup.
//       </h1>
//       <div className=" flex w-full flex-col gap-6 lg:gap-6 lg:flex-row lg:justify-center lg:items-center ">
//         <div className="flex justify-center flex-col items-center p-8 rounded-md h-44 w-44 shadow-md"
//           // style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
//         >
//           <p className=" text-primaryBlue text-3xl font-bold text-primaryBlue">7500+</p>
//           <p className=" text-base">Deals</p>
//         </div>
//         <div className=" flex flex-col gap-6">
//           <div className="px-4  flex flex-col justify-center items-center p-8 rounded-md h-44 w-44 shadow-md"
//           // style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
//           >
//             <p className=" text-primaryBlue text-3xl font-bold text-primaryBlue">10k+</p>
//             <p className=" text-base">Investors across</p>
//           </div>
//           <div className="px-4  flex flex-col justify-center items-center p-8 rounded-md h-44 w-44 shadow-md"
//           // style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
//           >
//             <p className=" text-primaryBlue text-3xl font-bold text-primaryBlue">50+</p>
//             <p className=" text-base"> sectors</p>
//           </div>
//         </div>
//         <div className="px-4  flex flex-col justify-center items-center p-8 rounded-md h-44 w-44 shadow-md"
//         // style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
//         >
//           <p className=" text-primaryBlue text-3xl font-bold text-primaryBlue">150k+</p>
//           <p className=" text-base">sources</p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default BigNumbers;