import React, { useEffect, useState } from "react";
import axios from "axios";
import excel from "../../assets/Microsoft_Excel-Logo.wine.svg";
import pdf from "../../assets/pdf-svgrepo-com.svg";
import Loader from "../../components/loader";
import dealFlo from "../../assets/dealflo.jpg";
import boon from "../../assets/boon (1).png";

const Download = () => {
  const mail = sessionStorage.getItem("email");
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setloading(true);
        if (mail) {
          const response = await axios.get(
            `https://factoq.com/backend/cdb/user_final?email=${mail}`
          );
          if (response.data && response.data[0] && response.data[0].orders) {
            setUserData(response.data);
          } else {
            setMessage("No downloads found.");
          }
        } else {
          setMessage("Please login to view this section.");
        }

        setloading(false);
      } catch (err) {}
    };
    fetchUserData();
  }, []);

  const downloadFile = async (id, name, type) => {
    try {
      if (type === "Sector") {
        const formattedName= name.toLowerCase().split(" ").join("_")
        const res = await axios.get(
          `https://factoq.com/backend/blob/sectorfile?sector=${formattedName}_sectorwise_investors.xlsx`,
          {
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const res = await axios.get(
          `https://factoq.com/backend/blob/fetchFile?filename=${id}.xlsx`,
          {
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {}
  };

  return (
    <div className="fadeIncrease rounded-xl shadow-xl items-start p-8 min-h-[500px] bg-[#fff] border-[1px] border-[#f1f1f1] gap-4 flex flex-col">
      <h1 className="font-bold text-xl">Download your investors list below.</h1>

      <div className="w-full border-[1px] border-[#e9e8e8] rounded-lg overflow-x-auto">
        {loading && (
          <div className="flex flex-col h-full items-center w-full">
            <Loader />
          </div>
        )}
        {userData &&
          Array.isArray(userData) &&
          userData.map((user, i) => {
            const epochTimestamp = user?._ts;
            const date = new Date(epochTimestamp * 1000).toLocaleString();
            return (
              <table key={i} className="table-auto text-start text-sm w-full">
                <thead>
                  <tr>
                    <th className="text-start p-3 border-b-[1px] border-[#e9e8e8]">
                      Company
                    </th>
                    <th className="text-start p-3 border-b-[1px] border-[#e9e8e8]">
                      Date
                    </th>
                    {/* <th className="text-start p-3 border-b-[1px] border-[#e9e8e8]">
                  Subscription
                </th> */}
                    <th className="text-start p-3 border-b-[1px] border-[#e9e8e8]">
                      Click to Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user.orders &&
                    user.orders.length > 0 &&
                    user.orders.map((order, index) => (
                      <tr
                        className="border-b-[1px] border-[#e9e8e8]"
                        key={index}
                      >
                        <td className="p-3 items-center">
                          {order.mca_company_name ?? order.sector}
                        </td>
                        <td className="p-3 items-center">{date}</td>
                        {/* <td className="p-3 items-center">Single</td> */}
                        <td className="p-3 items-center">
                          <div className="flex gap-2">
                            <button
                              className="bg-[#2b9746] flex gap-2 px-3 rounded-lg py-1 items-center w-max"
                              onClick={() =>
                                downloadFile(
                                  order.company_id,
                                  order.mca_company_name ?? (order.sector).toLowerCase(),
                                  order.type
                                )
                              }
                            >
                              <p className="text-[#fff] font-semibold">Excel</p>
                              <img src={excel} className="h-4" />
                            </button>
                            {/* <div className="bg-[#dd3b3d] flex gap-2 px-3 rounded-lg py-1 items-center w-max">
     <p className="text-[#fff] font-semibold">PDF</p>
     <img src={pdf} className="h-4" />
   </div> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            );
          })}

        {message && <p className="p-4 font-semibold text-xl">{message}</p>}
      </div>

      {userData && Array.isArray(userData) && userData.length > 0 && (
        <div className="flex flex-col gap-2 items-start text-xs pt-12">
          <label>
            Based on the list generated, Factacy Insights recommends the
            following institutions who can help you fundraise.
          </label>
          <div className="flex gap-3">
            <a href="https://booncapital.in/" target="_blank">
              <img src={boon} className="w-24 object-contain" />
            </a>
            <a href="https://booncapital.in/" target="_blank">
              <img src={dealFlo} className="w-24 object-contain" />
            </a>
          </div>
          <div className="flex gap-3">
            <button className="border-[1px] px-2 py-1 rounded-sm">
              Yes, Initiate Contact
            </button>

            <button className="border-[1px] px-2 py-1 rounded-sm">
              Ignore
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Download;
