import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { REGISTER_API } from "../constent/api";

const useAccountCreater = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const [error, setError] = useState('');

  const acCreater = async (values) => {
    setLoading(true);
    const res = await axios.post(REGISTER_API, {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      company: values.companyName,
      phone: values.phoneNumber,
      password: values.password,
      username: values.userName,
      gstin: values.gstin,
      id: sessionStorage.getItem("uuid") ? sessionStorage.getItem("uuid") : "",
    }).then((res) => {
      if (res?.data?.token) {
        sessionStorage.setItem('token', res?.data?.token);
        sessionStorage.setItem('email', values.email);
        if (sessionStorage.getItem('uuid')) {
          Navigate('/action/payment');
        } 
        else {
          Navigate('/');
        }

      } else if (res.data === "email id already exist") {
        setError("Email already exists!")
      } else {
        setError("Internal server error")
      }
      setLoading(false);
    }).catch((error) => {
      setError(error);
      setLoading(false);
    });
    return '';
  }
  return [loading, error, acCreater];
}
export default useAccountCreater;

