export const FAQs = {
    title: "FAQs",
    list: [
        {
            questions: "How does your platform curate investor lists for my company?",
            answer: "After reading through over 2 million articles and company filings, Factacy Insights’ AI algorithms analyze various data sources and identify investors relevant to your company based on factors such as industry, investment history, and portfolio preferences"
        },
        {
            questions: "How does your AI model determine which investors are most relevant to my startup?   ",
            answer: "Our AI model considers factors such as your company profile and stage and match that with the industry sector, investment stage, geographical location, past investment patterns, and specific preferences indicated by investors themselves"
        },
        {
            questions: "Is there a limit to the number of companies I can search and download investor lists for? ",
            answer: "Factacy insights AI engines can match up to 6 million companies with their potential investors. There's no limit to the number of companies you can search for or download investor lists for. You can access investor lists for as many companies as you need, based on your payment plan"
        },
        {
            questions: "How often are the investor lists updated with the latest investment data? ",
            answer: "Investor lists are updated in real-time to reflect the latest investment activities. For subscribers to our Multiple Downloads plan, we recommend downloading an updated list for your company every 2 months to ensure you have access to new investors relevant to you"
        },
        {
            questions: "In what format are the investor lists available for download?",
            answer: "The investor lists are available for download in both Excel and PDF formats, providing flexibility for users to analyze and utilize the data according to their preferences"
        },
        {
            questions: "Are there any additional features or insights included with the investor lists? ",
            answer: "In addition to the curated investor lists, we have partnered with distinguished Investment Bankers, Advisors and startup communities. These empaneled service providers can help your startup reach to your investors quickly and help you close your deal. We also offer insights into the investment landscape within your sector on www.factacyinsights.com"
        },
        {
            questions: "Can I preview the investor list before making a purchase? ",
            answer: "Yes, we offer a preview option to view some rows of the report, along with counts of investors contained in the report, that allows you to view a sample of the investor list before making a purchase"
        },
        {
            questions: "Are there usage restrictions for downloaded investor lists?",
            answer: "The investor lists are intended for internal use by your company only and should not be shared or distributed to third parties without prior authorization. Please refer to our terms of service for more information on acceptable use"
        },
        {
            questions: "How do I provide feedback or suggestions for improving the platform? ",
            answer: "We value your feedback and suggestions for improving our platform. You can submit your feedback directly through the platform or contact our customer support team. We regularly review user feedback to enhance our services and features"
        },
        {
            questions: "Will I get personal phone numbers?",
            answer: "We are not providing personal phone numbers, but we are sharing information available on their websites and other handles, ensuring that we adhere to the privacy policy of the investors while sharing this information"
        },
        {
            questions: "Is my company's information shared with third parties?",
            answer: "No, your company's information is not shared with third parties without your consent. We adhere to strict privacy policies to protect the confidentiality of your data"
        },
        {
            questions: "I am not able to search my company. What should I do in this case?",
            answer: "If you're unable to find your company listed, you can search for your closest competitor's company instead. Since they operate in the same industry, you'll likely find relevant investor lists that can provide valuable insights for your fundraising efforts. Simply enter your competitor's company name to access investor lists tailored to your industry."
        },
    ]
}