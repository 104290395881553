import React, { useState } from 'react';
import Loader from "../components/loader";
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik"
import { forgetPasswordSchema } from '../schemas/index';
import visibility_off from "../assets/visibility_off.svg";
import visibility_on from "../assets/visibility_on.svg";
// import { useAuth } from './authContext';
import { useSearchParams } from 'react-router-dom';

const PasswordComponent = ({ name, id, placeholder, value, change, blur }) => {
        const [showPassword, setShowPassword] = useState(false);
        const [inFocus, setInFocus] = useState(false);
    
        return (
                <div
                    className={` w-full flex rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem] ${inFocus ? " outline-primaryBlue outline-[0.1rem]" : ""
                        } `}
                    onBlur={() => setInFocus(false)}
        >
            <input
                className=" flex-1 outline-none border-none"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                name={name}
                id={id}
                placeholder={placeholder}
                onFocus={() => setInFocus(true)}
                onBlur={blur}
                value={value}
                onChange={change}
            />
            <img
                className=" cursor-pointer h-5"
                src={showPassword ? visibility_on : visibility_off}
                alt="visibility_icon"
                onClick={() => setShowPassword((pre) => !pre)}
            />
        </div>
    );
};

const Resetpassword = () => {
        const successfullMessage = "id recieved";
        const errorMessage = "error in recieving id";
    
        const [queries] = useSearchParams();
        const id = queries.get('email');
    
        const [loading, setLoading] = useState(false);
        const [response, setResponse] = useState('');
        const [showPassword, setShowPassword] = useState(false);
        const [inFocus, setInFocus] = useState(false);
    
        // const { login, error } = useAuth()
        // const [ login, error } = useAuth()
        const Navigate = useNavigate();
    
        // const loginFunction = async (userData) => {
            //     console.log(userData);
            //     try {
                //         setLoading(true)
                //         await login(userData)
                //         setLoading(false)
                //     } catch (error) {
                    //         console.error('Login failed:', error);
                    //         setLoading(false)
                    //     }
                    //     return '';
                    // }
                
                    const forgetPasswordFunction = async (valuse) => {
                            try {
                                    setLoading(true);
                                    const res = await fetch(`https://factacyinsights.in/updatepassword`,
                                        {
                                                method: "POST",
                                                headers: {
                                                        'Content-Type': 'application/json',
                                                        // 'Authorization': `Bearer ${token}`
                                                    },
                                                    body: JSON.stringify({
                                                            id,
                                                            password: values.password,
                                                        })
                                    
                                                    });
                                                if (res.ok) {
                                                        const data = await res.json();
                setResponse(data.message);
                setLoading(false);
            }


        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false)
        }
        return '';
    }

    const initialValues = {
            password: "",
            confirmPassword: "",
        };
        const { values, errors, touched, handleBlur, handleChange, handleSubmit, } = useFormik({
                validationSchema: forgetPasswordSchema,
                initialValues: initialValues,
                onSubmit: (values, action) => forgetPasswordFunction(values).then(res => Navigate('/'))
    })
    return (
            <div className=' h-screen w-screen flex justify-center items-center'>
                <div className=' overflow-y-auto rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] border-[#f1f1f1] w-[24rem]'>
                    <div className='fadeIncrease'>
                        <h1 className=" text-3xl mb-4 font-bold">
                            <span className=" text-primaryBlue">Forget Password</span>
                        </h1>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" flex flex-col gap-2 lg:gap-6">
                                <div className=" flex-1 flex flex-col gap-2">
                                    <div className=" flex gap-2 items-end">
                                        <p className=" text-md capitalize text-[#000000] font-medium">
                                            Password
                                        </p>
                                        <span className={`text-sm text-[#ff0000]`}>
                                            {errors.password && touched.password ? errors.password : " "}
                                        </span>
                                    </div>
                                    <PasswordComponent
                                        name={"password"}
                                        id={"password"}
                                    placeholder={"Password"}
                                    value={values.password}
                                    change={handleChange}
                                    blur={handleBlur}
                                />
                            </div>
                            <div className=" flex-1 flex flex-col gap-2">
                                <div className=" flex gap-2 items-end">
                                    <p className=" text-md capitalize text-[#000000] font-medium">
                                        Confirm Password
                                    </p>
                                    <span className={`text-sm text-[#ff0000]`}>
                                        {errors.confirmPassword && touched.confirmPassword
                                            ? errors.confirmPassword
                                            : " "}
                                    </span>
                                </div>
                                <PasswordComponent
                                    name={"confirmPassword"}
                                    id={"confirmPassword"}
                                    placeholder={"Confirm password"}
                                    value={values.confirmPassword}
                                    change={handleChange}
                                    blur={handleBlur}
                                />
                            </div>
                        </div>
                        <div className=" flex justify-center ">
                            {loading ? (<Loader />) :
                                !["", successfullMessage].includes(response) ? (<p className='text-[#FF0000] text-sm font-semibold'>Somthing went wrong while upadating your password</p>) : (<button type="submit" className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]">Update Password</button>)}
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default Resetpassword;

