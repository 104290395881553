import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/arrowBack.svg"
import insights from "../../assets/Insights.jpg"

const SideBar = () => {
  const list = [
    {
      text: "Find",
      url: "find",
    },
    {
      text: "Confirm",
      url: "confirm",
    },
    {
      text: "Register",
      url: "register",
    },
    {
      text: "Pay",
      url: "payment",
    },
    {
      text: "Download",
      url: "download",
    },
  ];
  const Navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(location.pathname.split("/").pop());
  }, [location]);
  return (
    <div className="flex flex-col justify-between h-full w-full">
    <div className="flex justify-between w-full items-start">
      <button className="text-sm font-semibold gap-1 text-[#717171] items-center hidden lg:flex pl-6 hover:text-[#000]" onClick={() => window.history.back()}>
        <img src={back} className="w-3" /><p>Back</p></button>
      <div className="flex items-center lg:items-start gap-0 lg:flex-col py-4 h-full  overflow-x-auto">
        {list.map((item, index) => (
          <React.Fragment key={item.url}>
            <button
              key={item.url}
              disabled
              onClick={() => Navigate(`/action/${item.url}`)}
              className={`${
                activeTab === item.url ? " text-primaryBlue" : "text-black"
              } lg:uppercase hover:text-primaryBlue cursor-pointer font-medium lg:p-4 p-2 text-sm`}
            >
              {item.text}
            </button>
            {list.length - 1 !== index && (
              <>
                <span className=" lg:hidden ">{">"}</span>
              </>
            )}
            {list.length - 1 !== index && (
              <>
                <hr className="text-[#f1f1f1] hidden lg:block w-full h-[1px]" />
              </>
            )}
          </React.Fragment>
        ))}
        
      </div>
      </div>
      <div className="flex items-center text-xs">
        <p>Powered by</p>
        <a href="https://www.factacyinsights.com" target="_blank"><img className="w-40 grayscale cursor-pointer" src={insights} /></a>
      
      </div>
      </div>

  );
};

export default SideBar;
