import React, { useState } from 'react';
import Loader from "../../components/loader";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik"
import { loginSchema } from '../../schemas';
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";
import useLogin from '../../Hooks/useLogin';
import axios from 'axios';

const Login = ({ setFormType, hideRegister }) => {
    const [loading, error, login] = useLogin();
    const [showPassword, setShowPassword] = useState(false);
    const [inFocus, setInFocus] = useState(false);

    const Navigate = useNavigate();

    const loginAPIHandlear = async (values) => {
        console.log(values);
        axios.post("https://factoq.com/backend/cdb/inv_login", {
            email: values.email,
            password: values.password,
        }).then((res) => {
            if (res?.data?.token) {
                sessionStorage.setItem('token', res?.data?.token);
                sessionStorage.setItem('email', values.email);
                Navigate('/action/find');
            }
        }).catch(err => console.log(err));
        return '';
    }

    const initialValues = {
        email: "",
        password: "",
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, } = useFormik({
        validationSchema: loginSchema,
        initialValues: initialValues,
        onSubmit: (values, action) => {
            login(values).then(action.resetForm());
        }
    })

    return (
        <div className='fadeIncrease w-[20rem]'>
            <h1 className=" text-3xl mb-4 font-bold">
                <span className=" text-primaryBlue">Login.</span>
            </h1>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    {/* <div className="flex flex-col gap-4"> */}
                    <div className=" flex-1 flex flex-col gap-2">
                        <div className=" flex gap-2 items-end">
                            <p className=" text-md capitalize text-[#000000] font-medium">
                                Email
                            </p>
                            <span className={`text-sm text-[#ff0000]`}>
                                {errors.email && touched.email ? errors.email : " "}
                            </span>
                        </div>
                        <input
                            className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                            type="email"
                            autoComplete="off"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className=" flex-1 flex flex-col gap-2">
                        <div className=" flex gap-2 items-end">
                            <p className=" text-md capitalize text-[#000000] font-medium">
                                Password
                            </p>
                            <span className={`text-sm text-[#ff0000]`}>
                                {errors.password && touched.password ? errors.password : " "}
                            </span>
                        </div>
                        <div className={` w-full flex rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem] ${inFocus ? " outline-primaryBlue outline-[0.1rem]" : ""} `}
                            onBlur={() => setInFocus(false)}
                        >
                            <input
                                className=" flex-1 outline-none border-none"
                                type={showPassword ? "text" : "password"}
                                autoComplete="off"
                                name="password"
                                id="password"
                                placeholder="password"
                                onFocus={() => setInFocus(true)}
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                            />
                            <img
                                className=" cursor-pointer h-5"
                                src={showPassword ? visibility_on : visibility_off}
                                alt="visibility_icon"
                                onClick={() => setShowPassword((pre) => !pre)}
                            />
                        </div>
                    </div>
                </div>
                <span className=' text-buttonCTA font-semibold cursor-pointer text-sm' onClick={() => setFormType("forget")}>Forgot Password ?</span>
                <div className=" flex justify-center ">
                    {loading ? (<Loader />) : (<button type="submit" className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]">Login</button>)}
                </div>
            </form>
            {error &&
            <p className='text-[#ff0000]'>Incorrect email or password</p>}
            {(<p className=' mt-4 text-md'>Do not have an account? <span className=' text-buttonCTA font-semibold cursor-pointer' onClick={() => setFormType("register")}>Register</span></p>)}
        </div>
    );
};

export default Login;