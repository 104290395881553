import React, { useState } from 'react';
import { useFormik } from "formik"
import Loader from '../../components/loader';
import { emailSchema } from '../../schemas';

const Forget = ({ setFormType }) => {
    const useNotExistError = "something went wrong";
    const linkSentSuccessfully = "Reset link sent";

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const [input, setInput] = useState('');

    const requestRestLink = async (values) => {
        try {
            setLoading(true);
            const res = await fetch(`https://factacyinsights.in/forgotpassword?email=${values.email}&site=${window.location.host}`);
            if (res.ok) {
                const data = await res.json();
                setResponse(data.message);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    }

    const initialValues = {
        email: "",
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, } = useFormik({
        validationSchema: emailSchema,
        initialValues: initialValues,
        onSubmit: (values, action) => {
            requestRestLink(values);
        }
    })


    return (
        <div className='fadeIncrease w-[20rem]'>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <div className=" flex-1 flex flex-col gap-2">
                        <div className=" flex gap-2 items-end">
                            <p className=" text-md capitalize text-[#000000] font-medium">
                                Email
                            </p>
                            <span className={`text-sm text-[#ff0000]`}>
                                {errors.email && touched.email ? errors.email : " "}
                            </span>
                        </div>
                        <input
                            className=" max-w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                            type="email"
                            autoComplete="off"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                <div className=" flex justify-center ">
                    {loading ? (<Loader />) :

                        response ?
                            response === linkSentSuccessfully ?
                                (<span>Password reset link sent successfully. Check your inbox</span>)
                                :
                                (<div className=' flex flex-col items-center justify-center mt-2'>
                                    <span className=' text-[#FF0000] text-sm font-semibold mb-1'>This email doesn't exist. Please enter correct email id</span>
                                    <button type="submit" className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]">Get Link</button>
                                </div>)
                            :
                            (<button type="submit" className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]">Get Link</button>)
                    }
                </div>
            </form>
            {(<p className=' mt-4 text-md'>Have an account? <span className=' text-buttonCTA font-semibold cursor-pointer' onClick={() => setFormType("login")}>Login</span></p>)}
        </div>
    );
}
export default Forget;